<template>
  <a-spin :spinning="!isPageLoaded">
    <router-header :routerHeaderInfo="routerHeaderInfo"></router-header>

    <a-form ref="form"
            :model="form"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            validateTrigger="blur">
      <a-form-item label="微信支付"
                   name="isOpen"
                   placeholder="请输入">
        <a-radio-group v-model:value="form.isOpen">
          <a-radio :value="1">开启</a-radio>
          <a-popconfirm title="确认关闭微信支付？"
                        ok-text="确定"
                        cancel-text="取消"
                        @confirm="closeWechetPay">
            <a-radio :value="0">关闭</a-radio>
          </a-popconfirm>
        </a-radio-group>
        <div v-if="form.isOpen"
             class="setup-info">
          <p class="setup-step">1. <a :href="weChatOffcialUrl"
               target="_blank">微信公众号平台</a>的左侧栏【公众号设置】-【功能设置】-【JS接口安全域名】，配置
            scrm.edusoho.com</p>
          <p class="setup-step">2. <a :href="weChatOffcialUrl"
               target="_blank">微信公众号平台</a>的左侧栏【公众号设置】-【功能设置】-【网页授权域名】，配置
            scrm.edusoho.com</p>
          <p class="setup-step">3. <a :href="weChatOffcialUrl"
               target="_blank">微信公众号平台</a>的左侧栏【开发】-【基本设置】-【公众号开发信息】，找到IP白名单，增加IP：116.62.59.181 </p>
          <p class="setup-step">4. <a :href="weChatMerchantUrl"
               target="_blank">微信商户号平台</a>的【微信支付】-
            【产品中心】-【开发配置】-【支付授权目录】配置
            https://scrm.edusoho.com/customer-h5/</p>
        </div>
      </a-form-item>
      <template v-if="form.isOpen">
        <a-form-item label="商户号"
                     name="mchid"
                     placeholder="请输入">
          <a-input v-model:value="form.mchid" />
          <p class="setup-step">商户号来自<a :href="weChatOffcialUrl"
               target="_blank">微信公众号平台</a>内左侧栏【微信支付】-【商户信息】</p>
        </a-form-item>
        <a-form-item label="AppID"
                     name="appId"
                     placeholder="请输入">
          <a-input v-model:value="form.appId" />
          <p class="setup-step">服务号AppID来自<a :href="weChatOffcialUrl"
               target="_blank">微信公众号平台</a>服务号内,在左侧栏【开发】-【基本配置】-【开发者ID中】</p>
        </a-form-item>
        <a-form-item label="AppSecret"
                     name="appSecret"
                     placeholder="请输入">
          <a-input v-model:value="form.appSecret" />
          <p class="setup-step">服务号AppSecret来自<a :href="weChatOffcialUrl"
               target="_blank">微信公众号平台</a>服务号内,在左侧栏【开发】-【基本配置】-【开发者ID中】
          </p>
        </a-form-item>
        <a-form-item label="MP文件验证码"
                     name="mpFileCode"
                     placeholder="请输入">
          <a-input v-model:value="form.mpFileCode" />
          <p class="setup-step"><a :href="weChatOffcialUrl"
               target="_blank">微信公众号平台</a>服务号 - 「公众号设置」 -
            「功能设置」-「网页授权域名」，在“设置”页面，下载MP_verify...文件，打开文件，复制文件内容。</p>
        </a-form-item>
        <a-form-item label="ApiV3密钥"
                     name="apiVersionThreeKey"
                     placeholder="请输入">
          <a-input v-model:value="form.apiVersionThreeKey" />
          <p class="setup-step">微信商户API密钥需要登录<a :href="weChatMerchantUrl"
               target="_blank">微信商户号平台</a>在【账户设置】-【API安全】中设置密钥
          </p>
        </a-form-item>
        <a-form-item label="证书序列号"
                     name="certSerialNumber"
                     placeholder="请输入">
          <a-input v-model:value="form.certSerialNumber" />
          <p class="setup-step">微信商户API密钥需要登录<a :href="weChatMerchantUrl"
               target="_blank">微信商户号平台</a>在【账户设置】-【API安全】-「API证书」- 「查看证书」
          </p>
        </a-form-item>
        <a-form-item label="微信商户私钥文件"
                     name="privateKeyFile"
                     placeholder="请输入">
          <a-upload :file-list="fileList"
                    name="privateKeyFile"
                    :customRequest="upLoadApiKey"
                    @change="handleApiImgChange"
                    :before-upload="beforeUpload"
                    accept=".pem">
            <a-button>
              <upload-outlined></upload-outlined>
              点击上传
            </a-button>
            <span v-if="isPemSuccess"
                  class="pem-success">证书已上传
              <CheckCircleOutlined />
            </span>
          </a-upload>
          <p class="setup-step">微信商户证书需前往<a :href="weChatMerchantUrl"
               target="_blank">微信商户平台</a>并<a :href="apiSecurityCertificateUrl"
               target="_blank">生成API安全证书</a>
            在【账户设置】-【API安全】-"查看证书文件夹"找到"apiclient_key.pem"并上传</p>
        </a-form-item>
      </template>

    </a-form>
    <div v-if="form.isOpen"
         class="setup-submit">
      <a-button type="primary"
                @click="onSubmit">提交</a-button>
    </div>
  </a-spin>
</template>

<script>
import _ from "lodash";
import wechatSettingApi from "@/service/api/wechatSetting";
import { Radio } from "ant-design-vue";
import RouterHeader from "@/components/RouterHeader";

export default {
  name: "SetUpWechat",

  components: {
    ARadio: Radio,
    ARadioGroup: Radio.Group,
    RouterHeader,
  },

  data() {
    const rules = {
      appId: [
        {
          required: true,
          message: "请输入AppleID",
        },
      ],
      appSecret: [
        {
          required: true,
          message: "请输入AppSecret",
        },
      ],
      mchid: [
        {
          required: true,
          message: "请输入商户号",
        },
      ],
      apiVersionThreeKey: [
        {
          required: true,
          message: "请输入ApiV3密钥",
        },
      ],
      certSerialNumber: [
        {
          required: true,
          message: "请输入证书序列号",
        },
      ],
      privateKeyFile: [
        {
          required: true,
          message: "请上传证书",
        },
      ],
      mpFileCode: [
        {
          required: true,
          message: "请填写MP文件验证码",
        },
      ],
    };
    const routerHeaderInfo = [
      {
        path: "/enterprise/systemSetting",
        name: "系统设置",
      },
      { name: "企业支付" },
    ];
    return {
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 14,
      },
      isPageLoaded: false,
      rules,
      routerHeaderInfo,
      fileList: [],
      isPemSuccess: false,
      weChatOffcialUrl: "https://mp.weixin.qq.com",
      weChatMerchantUrl: "https://pay.weixin.qq.com",
      apiSecurityCertificateUrl:
        "https://kf.qq.com/faq/161222NneAJf161222U7fARv.html",
      form: {
        appId: "",
        appSecret: "",
        mchid: "",
        isOpen: undefined,
        apiVersionThreeKey: "",
        certSerialNumber: "",
        privateKeyFile: "",
        mpFileCode: "",
      },
    };
  },

  created() {
    this.getSetting();
  },

  methods: {
    async upLoadApiKey(file) {
      const formData = new FormData();
      formData.append("privateKeyFile", file.file);

      const res = await wechatSettingApi.uploadPrivateKey(formData);
      this.form.privateKeyFile = res;
      file.onSuccess(res, file.file);
      this.isPemSuccess = true;
    },
    beforeUpload(file) {
      this.fileList = [];

      const isPem = _.endsWith(file.name, ".pem");

      if (!isPem) {
        this.$message.error("只能上传pem格式文件！");
      }

      const isLt1M = file.size / 1024 / 1024 < 1;

      if (!isLt1M) {
        this.$message.error("文件必须小于1MB！");
      }

      return isPem && isLt1M;
    },
    handleApiImgChange({ file, fileList }) {
      if (file.status === "uploading") {
        this.fileList = fileList; //必须要
        return;
      }
      if (file.status === "done") {
        this.$message.success("上传成功");
      }
      if (file.status === "error") {
        this.$message.error("上传失败");
      }
    },

    async closeWechetPay() {
      await wechatSettingApi.save({
        isOpen: 0,
      });
      this.form.isOpen = 0;
      this.$message.success("已关闭");
    },
    async onSubmit() {
      this.$refs.form.validate().then(async () => {
        await wechatSettingApi.save(this.form);
        this.$message.success("保存成功,微信支付已开启");
      });
    },
    async getSetting() {
      this.isPageLoaded = false;

      this.form = await wechatSettingApi.getPaySetting();
      if (!this.form.isOpen) {
        this.form.isOpen = 0;
      }
      this.isPageLoaded = true;

      if (this.form.privateKeyFile) {
        this.isPemSuccess = true;
      }
    },
  },
};
</script>